import io from 'socket.io-client';

/**
 * An event message to be sent to the socket server
 *
 * @interface SocketMessage
 */
export interface SocketMessage {
    event: string;
    //if undefined, the message is sent to the server but not forwarded.
    receiverType?: string;
    content: any;

    message?: string;
    senderType?: string;
    receiverId?: string | number;
}

/**
 * A subscribable event to be received from the server
 *
 * @interface SocketEvent
 */
export interface SocketEvent {
    id: number;
    event: string;
    callback(data: any): void;
}

/**
 * Parameters needed to be authenticated by the notification server
 *
 * @interface Authentication
 */
interface Authentication {
    id: number;
    type: string;
    token: string;
}

export interface VideoChatUpdate {
    id: string;
    clientId: number;
    performerId: number;
    message: string;
    type: VideoChatType;
    sessionType: string;
    value: boolean | any;
}

type VideoChatType = 'REQUEST' | 'CANCEL_REQUEST' | 'DISCONNECT' | 'RECONNECTED' | 'PEEK_ACTIVATED' | 'PEEK_DISCONNECT' | 'START_TIMER' | 'START_TIMER_DEVICE' | 'VOYEUR_DISCONNECT' | 'VOYEUR_ACTIVATED' | 'ACTIVATED';

/**
 * Manages the socket connection to the Notification server
 *
 * @class NotificationSocket
 */
export class NotificationSocket {
    private url: string;

    private socket: SocketIOClient.Socket | undefined;

    private pingMessage: string;
    private pongMessage: string;

    private subscribedEvents: SocketEvent[];
    private messageQueue: { event: string; content: string }[];

    private checkAliveInterval: number;
    private pingTimeout: number;
    private reconnectTimeout: number;

    private lastPongTime: number;
    private lastReconnectTime: number;

    private intervalHandle: any;

    private authentication!: Authentication;

    private eventId: number = 0;
    private connectionStatus: 'disconnected' | 'connecting' | 'connected' = 'disconnected';

    constructor() {
        this.url = '';

        this.pingMessage = 'tits';
        this.pongMessage = 'ass';

        this.messageQueue = [];
        this.subscribedEvents = [];

        this.checkAliveInterval = 6000;
        this.pingTimeout = 10000;
        this.reconnectTimeout = 10000;

        this.lastPongTime = Date.now();
        this.lastReconnectTime = Date.now();
    }

    /**
     * Makes a connection to the socket server, registers your user account on it, and starts receiving events
     */
    connect(url: string, authentication: Authentication) {
        const options = {
            forceNew: true,
            reconnection: false, //handle reconnections are self (ping pong)
            reconnectionDelay: 5000,
            reconnectionDelayMax: 10000,
            transports: ['websocket', 'polling']
        };

        //Check if the socket connection is alive on interval
        if (this.isConnected()) {
            throw new Error('You already have an active socket connection. Close it before proceeding');
        }

        this.connectionStatus = 'connecting';

        this.lastReconnectTime = Date.now();

        this.socket = io.connect(url, options);
        this.url = url;
        this.authentication = authentication;

        this.socket.on('connect', this.socketConnect.bind(this));
        this.socket.on('disconnect', this.socketDisconnect.bind(this));
        this.socket.on('receivedEvent', this.socketReceivedEvent.bind(this));
        this.socket.on('connect_error', this.socketDisconnect.bind(this));
    }

    async connection() {
        return new Promise<{ error?: string }>((resolve, reject) => {
            if (this.connectionStatus == 'connected') {
                resolve({});
                return;
            }

            const timeout: any = setTimeout(() => {
                this.unsubscribe(id);
                resolve({ error: 'socket-timeout' });
            }, 3000);

            var id = this.subscribe('authenticated', () => {
                this.unsubscribe(id);
                clearTimeout(timeout);
                resolve({});
            });

            if (this.connectionStatus == 'disconnected') {
                this.connect(this.url, this.authentication);
            }
        });
    }

    /**
     * Breaks the connection to the socket server, the subscribed events will remain
     */
    disconnect() {
        if (!this.socket) {
            console.log('Disconnecting socket disallowed');
            return;
        }

        this.socket.removeAllListeners();
        this.socket.disconnect();
        this.connectionStatus = 'disconnected';
        this.socket = undefined;

        if (this.intervalHandle) {
            clearInterval(this.intervalHandle);

            delete this.intervalHandle;
        }
    }

    /**
     * Checks if there is an active socket connection
     */
    isConnected(): boolean {
        if (!this.socket) {
            return false;
        }
        return this.socket.connected;
    }

    /**
     * Subscribe a callback to a certain event type. The callback will be triggered once the event will be received over the socket
     *
     * @param eventName - The name of the event you want to subscribe too
     * @param callback - Callback that will be fired once the event occurs
     *
     * @return number - Returns an unique id that can be used to unsubscribe from the event
     */
    subscribe(eventName: string, callback: (data: any) => void): number {
        const uniqId = ++this.eventId;

        this.subscribedEvents.push({
            id: uniqId,
            event: eventName,
            callback: callback
        });

        return uniqId;
    }

    /**
     * Unsubscribe from an event using the unique identifier
     *
     * @param id - The unique id used to identify the event. Retrieved from the subscribe function
     */
    unsubscribe(id: number) {
        this.subscribedEvents = this.subscribedEvents.filter(subEvent => subEvent.id !== id);
    }

    /**
     * Unsubscribe from an event using the unique identifier
     *
     * @param id - The unique id used to identify the event. Retrieved from the subscribe function
     */
    unsubscribeEvent(eventName: string) {
        this.subscribedEvents = this.subscribedEvents.filter(subEvent => subEvent.event !== eventName);
    }

    /**
     * Send an event over the socket connection
     *
     * @param message - The content of the event message
     */
    sendEvent(message: SocketMessage) {
        message.content = encodeURIComponent(JSON.stringify(message.content));

        if (message.receiverId && typeof message.receiverId !== 'string') {
            message.receiverId = message.receiverId.toString();
        }

        this.sendCustomEvent('event', message);
    }

    /**
     * Send a message over the socket connection
     *
     * @param type - Message name
     * @param content - Content of the message
     */
    sendCustomEvent(type: string, content: any) {
        if (typeof content === 'object') {
            content = JSON.stringify(content);
        }

        if (!this.isConnected()) {
            this.messageQueue.push({
                event: type,
                content: content
            });

            return;
        }

        this.socket && this.socket.emit(type, content);
    }

    sendLocalEvent(type: string, content: any) {
        this.processEvent(type, content);
    }

    private socketConnect(user: number, role: string, token: string) {
        this.processEvent('connected', { type: 'SOCKET' });
        this.connectionStatus = 'connected';
        setTimeout(() => {
            if (!this.socket) {
                return;
            }

            this.socket.emit('user', this.authentication, () => {
                if (this.connectionStatus == 'connecting') {
                    this.connectionStatus = 'connected';
                }
                this.processEvent('authenticated', {});
                this.processQueue();
            });

            if (!this.intervalHandle) {
                this.intervalHandle = setInterval(this.checkSocketAlive.bind(this), this.checkAliveInterval);
            }
        }, 1000);
    }

    private socketDisconnect(reason: string) {
        this.connectionStatus = 'disconnected';
        //console.info(`[NotificationSocket] Disconnect with reason: ${reason}`);

        this.processEvent('disconnected', { type: 'SOCKET' });
    }

    private socketReceivedEvent(data: string) {
        if (!data || data === '{}') {
            return;
        }

        const parsedData: SocketMessage = JSON.parse(data);

        // console.info('[NotificationSocket] Received the following event from the server: ', parsedData);

        // Allow API forced reloading of the page when a performer is BLOCKED
        if (parsedData.event == 'forceReload') {
            console.log('Reload forced by API');
            location.reload();
        }        

        if (!parsedData.event || this.isPongEvent(parsedData.event) || (!parsedData.content && !parsedData.message)) {
            return;
        }

        if (parsedData.event === 'msg') {
            parsedData.content = {
                receiverType: parsedData.receiverType,
                senderType: parsedData.senderType,
                message: parsedData.message
            };
        } else if (parsedData.event === 'msgt') {
            let payload = JSON.parse(decodeURIComponent(parsedData.content));
            parsedData.content = {
                receiverType: parsedData.receiverType,
                senderType: payload.senderType,
                language: payload.language,
                languageTranslate: payload.languageTranslate,
                message: payload.content.replace(/\+/g, ' '),
                messageTranslate: payload.contentTranslate != null ? payload.contentTranslate.replace(/\+/g, ' ') : payload.contentTranslate
            };
        } else {
            parsedData.content = JSON.parse(decodeURIComponent(parsedData.content));
        }

        this.processEvent(parsedData.event, parsedData.content);
    }

    private checkSocketAlive() {
        if (this.isConnected()) {
            this.socket && this.socket.emit(this.pingMessage, '');
        }

        const timeSinceLastPong = Date.now() - this.lastPongTime;
        const timeSinceLastReconnect = Date.now() - this.lastReconnectTime;

        if (timeSinceLastPong > this.pingTimeout && timeSinceLastReconnect > this.reconnectTimeout) {
            //console.info(`[NotificationSocket] Attempting to reconnect. Last pong: ${timeSinceLastPong} Last Reconnect: ${timeSinceLastReconnect}`);

            this.disconnect();
            this.connect(this.url, this.authentication);
        }
    }

    private isPongEvent(evt: string): boolean {
        if (evt === this.pongMessage) {
            this.lastPongTime = Date.now();

            return true;
        }

        return false;
    }

    private processEvent(evt: string, content: Object) {
        const matchingEvents = this.subscribedEvents.filter(subEvent => subEvent.event === evt);

        matchingEvents.forEach(event => event.callback(content));
    }

    private processQueue() {
        if (!this.isConnected() || this.messageQueue.length === 0) {
            return;
        }

        const firstMessage = this.messageQueue.shift();

        if (!firstMessage) {
            return;
        }

        this.socket && this.socket.emit(firstMessage.event, firstMessage.content, this.processQueue.bind(this));
    }
}

const notifications = new NotificationSocket();

export default notifications;

//nice little helper
export async function connect(url: string, authentication: Authentication) {
    if (notifications.isConnected()) {
        notifications.disconnect();
    }

    return new Promise<void>((resolve, reject) => {
        notifications.connect(url, authentication);
        const subscriptions = [
            notifications.subscribe('authenticated', () => {
                subscriptions.forEach(id => notifications.unsubscribe(id));
                resolve();
            }),
            notifications.subscribe('disconnected', () => {
                subscriptions.forEach(id => notifications.unsubscribe(id));
                reject();
            })
        ];
    });
}
